import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import SEO from '../components/seo';
import Layout from '../features/layout/layout';
import AboutPage from './about';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      // TEMPORARILY HOME IS REDIRECTED TO ABOUT COMPONENT!

      <AboutPage />

      // <Layout location={this.props.location} title={siteTitle}>
      //   <SEO title="All posts" />
      //   <Bio />
      //   {posts.map(({ node }) => {
      //     const title = node.frontmatter.title || node.fields.slug;
      //     return (
      //       <article key={node.fields.slug}>
      //         <header>
      //           <h3
      //             style={
      //               {
      //                 // marginBottom: rhythm(1 / 4),
      //               }
      //             }
      //           >
      //             <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
      //               {title}
      //             </Link>
      //           </h3>
      //           <small>{node.frontmatter.date}</small>
      //         </header>
      //         <section>
      //           <p
      //             dangerouslySetInnerHTML={{
      //               __html: node.frontmatter.description || node.excerpt,
      //             }}
      //           />
      //         </section>
      //       </article>
      //     );
      //   })}
      // </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
